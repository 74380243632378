import {get,post} from "./request"
// // ------------------首页-------------------
// // 获取用户信息
export const getUinfo = params=>get("shop/home/shopInfo",params)
// // 获取店铺订单数据集合
export const getDatas = params=>get("shop/home/getOrderData",params)
// // 获取7天内数据
export const getAweekData = params=>get("shop/home/getSmsData",params)
// // 获取首页任务列表
export const getHomeTaskList = params=>get("shop/home/getTaskList",params)
// // 获取公告列表
export const getNotice = params=>get("shop/Notice/index",params)
// // 获取验证码
export const getCode = data =>post("shop/home/sendShopMobile",data)
// // 绑定手机号
export const bindMobile = params => get("shop/home/checkCode",params)
// // 获取短信剩余和使用数量
export const getSmsMsg = params=>get("shop/home/getSmsNumber",params)
// // 退出登录
export const getOut=params=>get("shop/shop/loginOut",params)
// 获取活动轮播图
export const getActivity = params => get("shop/home/getBanner", params)
// 获取导航栏外链部分
export const getOutLinks = params => get("shop/home/getLinks", params)

// //-------------------短信---------------------
// // 获取模板分类
export const getTemplateType = params=>get("shop/Template/getCateList",params)
// // 获取模板列表
export const getTemplateList = params=>get("shop/Template/getList",params)
// // 创建自定义模板
export const createTemplate = data =>post("shop/Template/add",data)
// // 生成短链接
export const createShortLink = data=>post("shop/Template/createUrl",data)
// 删除自定义模板
export const delTemplate = data=>post("shop/Template/delTemplate",data)

// // ------------------会员----------------------
// // 获取会员列表
export const getCustomerList=params=>get("shop/user/getList",params)
// // 筛选会员
export const screeningMembers = params=>get("shop/Batch_Sender/appointUserTotal",params)
// // 添加黑名单
export const addBlackList = data=>post("shop/User/addUserBlacklist",data)
// // 获取黑名单列表
export const getBlackList = params=>get("shop/User/getUserBlacklist",params)

// // ------------------任务-----------------------
// // 修改任务状态
export const changeTask = data=>post("shop/task/status",data)
// // 获取短信发送记录
export const getSmsSend = params=>get("shop/Sms_log/getList",params)
// // 获取修改任务信息
export const getModifyTaskMsg = params =>get("shop/task/getInfo",params)
// // 修改任务
export const ModifyTask = data=>post("shop/Task/save",data)
// // 创建任务
export const createTask = data=>post("shop/Task/add",data)
// // 删除任务
export const delTask = data=>post("shop/task/del",data)
// // 获取每个环节任务列表
export const getTaskList = params=>get("shop/task/getList",params)
// // 修改任务优先级
export const modifyTaskLevel = data=>post("shop/task/sort",data)
// // 获取商品列表
export const getGoodsList = params=>get("shop/Goods/getGoodsSku",params)
// 同步商品
export const refreshGoods = params => get("shop/Goods/syncGoods",params)
// // 催付效果分析
export const expeditingEffect = params=>get("shop/Sms_log/getAnalysisList",params)
// 催评效果分析
export const commentEffect = params=>get("shop/Sms_log/commentAnalysisList",params)
// // 测试发送
export const testsend = data=>post("shop/Send/testSend",data)
// 获取短信变量，签名集合
export const getVariable = params=>get("shop/Template/getPropList",params)
// 获取短信回复记录
export const getReplyList = params=>get("shop/Sms_log/getReplyList",params)
// 重新发送短信
export const resendSms = data => post("shop/Sms_log/reSend", data)
// 批量重新发送短信
export const batchResendSms = data => post("shop/Sms_log/reSends", data)
// 获取开启任务数
export const getTaskNum = params=>get("shop/Task/taskTypeCount",params)
// 申请退款分析
export const requestRefund = params => get("shop/Sms_log/afterSalesAnalysisList",params)

// // ------------------短信充值----------------------
// // 获取充值金额分类
export const getPayType = params=>get("shop/pay/getList",params)
// // 获取充值记录列表
export const getRechargeList = params=>get("shop/pay/getLogList",params)
// // 发起支付请求
export const payRequest = data=>post("shop/pay/add",data)
// // 获取支付结果
export const payResult = params =>get('shop/pay/checkIsPay',params)

// //-------------------订单---------------------
// // 获取订单列表
export const getOrderList = params=>get("shop/order/getList",params)
// // 提交短信发送任务
export const submitSendTask = data=>post("shop/Batch_Sender/sends",data)
// // 查询订单明细
export const queryOrderDetailed = params=>get("shop/order/getOrderIdsList",params)
// // 获取指定订单的订单商品详情
export const getSkuList = params=>get("shop/order/getItemInfo",params)
// 获取所有订单ID
export const getAllOrderId = params=>get("shop/Batch_Sender/allOrder",params)

// // -------------------群发---------------------
// // 导入订单群发
export const importOrder=data =>post("shop/Batch_Sender/sends",data)
// // 导入号码群发
export const mobileSend = data =>post("shop/Batch_Sender/appointMobile",data)
// // 获取群发任务列表
export const getGroupSendList = params=>get("shop/send/getList",params)
// // 获取群发任务详情列表
export const getSendList = params=>get("shop/Send/getSendLogList",params)
// 提交任务前判断短信是否充足
export const judge = data=>post("shop/Batch_Sender/sendNumberSum",data)

// // ---------------------人群-------------------
// // 通过标签预估人群数
export const estimatePeople = data =>post("shop/User/crowdTotal",data)
// // 保存人群
export const holdCrowd = data =>post("shop/User/addCrowd",data)
// // 获取人群列表
export const getCrowdList = params=>get("shop/User/getCrowdList",params)
// // 创建人群短信发送任务
export const crowdMsgSend = data=>post("shop/User/crowdMarketing",data)
// // 获取人群发送列表
export const getCrowdSendList = params=>get("shop/Crowd/getCrowdLog",params)
// // 轮询修改发送状态
export const changeSentStatus = data=>post("shop/Crowd/pollingCrowdLog",data)
// // 删除人群策略
export const delCrowd = data=>post("shop/User/delCrowd",data)
// // 更新人群数量
export const upDateCrowdNum = data=>post("shop/User/refreshCrowdCount",data)
// // 系统人群初始化创建
export const getSystemCrowd = data=>get("shop/User/addSysCrowd",data)

// // -----------------------个人中心----------------------------
// // 修改店铺签名
export const changeLabel = data=>post("shop/shop/addSign",data)
// // 短信签名修改记录
export const changeLabelRecord = params=>get("shop/shop/getSignList",params)
// // 获取个人中心配置
export const getDispose = params=>get("shop/shop/getShopConfig",params)
// // 保存软件到期和短信剩余提醒
export const remind = data=>post("shop/shop/getShopConfig",data)
// 设置默认签名
export const setDefaultSign = data => post("shop/shop/saveDefaultSign", data)

// // -----------------公告-----------------
// // 获取公告详情
export const getDetails = params=>get("shop/Notice/show",params)

// -----------------评价管理------------------
// 获取评价详情
export const getEvaluateDetails = params=>get("shop/Comment/getList",params)
// 获取评价统计数据
export const getEvaluationStatistics = params=>get("shop/Comment/commentData",params)
// 获取自动回复配置详情
export const getAutomaticReplyDetails = params=>get("shop/Comment/getInfo",params)
// 自动回复设置
export const automaticReplySet = data=>post("shop/Comment/save",data)
// 手动回复评价
export const manualReply = data=>post("shop/Comment/batchReply",data)
// 获取评分趋势图数据
export const getScore = params=>get("shop/Comment/commentDsr",params)

//----------------多店铺绑定-----------------
// 获取绑定店铺信息
export const getBindShopMsg = params=>get("shop/Multi/info",params)
// 绑定主店铺
export const bindMainShop = data=>post("shop/Multi/addMain",data)
// 绑定子店铺
export const bindSubShop = data=>post("shop/Multi/addSon",data)
// 多店铺绑定获取code
export const getShopCode = data=>post("shop/Multi/getCode",data)
// 获取多店铺列表
export const getShopList = params=>get("shop/Multi/shopSwitch",params)
// 切换店铺
export const switchShop = data=>post("shop/Multi/shopuse",data)
// 移出子店铺
export const removeShop = data => post("shop/Multi/rmShop", data)
// 移出主店铺
export const removeBoosShop = data => post("shop/Multi/rmShops", data)

// -------------------发件人管理---------------------
// 获取发件人列表
export const getAddresserLiet = params => get("shop/task/getFromList", params)
// 新增发件人
export const addAddresser = data => post("shop/task/addFrom", data)
// 删除发件人
export const delAddresser = data => post("shop/task/delFrom", data)
// 修改发件人优先级
export const changeAddresserSort = data => post("shop/task/sortFrom", data)